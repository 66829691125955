import 'bootstrap';
import '../scss/style.scss';

jQuery(function ($) {
  $('form.find-advisor').each(function () {
    var form = this;
    var zip = $(this).find('input[name="zipCode"]').get(0);
  
    $(zip).on('input', function (e) {
      if (this.validity.valid) {
        $(form).find('.error-msg').text('');
      }
    });
  
    $(form).on('submit', function (e) {
      if (!zip.validity.valid) {
        e.preventDefault();
        $(this).addClass('submitted').find('.error-msg').text('Please enter a ZIP code');
      } else {
        $(form).trigger('submit-valid', [e, $(zip).val()]);
      }
    });
  });
  
  $('.track-video').on('click', function() {
    $(this).next('iframe.yt').attr('src', function (index, src) {
      return src + '?autoplay=1&rel=0';
    }).end().hide();

    if (typeof yahooPixel === 'function') { yahooPixel('ria'); }
    if (typeof FbOnClickTracking === 'function') { FbOnClickTracking('purchase'); }
    if (typeof MqOnClickTracking === 'function') { MqOnClickTracking('siteactivity'); }
  });
});
